<template>
  <!-- <el-dialog title="书城账号登录" :visible.sync="show" :before-close="handleClose" width="450px"
             :modal-append-to-body="false"
             :close-on-click-modal="false"> -->
  <div class="account-login-edit">
    <el-form :model="form" label-width="70px" v-loading="loading">
      <el-form-item label="用户名" required>
        <el-input
          v-model="form.userName"
          style="width: 100%"
          placeholder="请输入用户名"
          :disabled="!!form.id"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" :required="!form.id">
        <el-input
          v-model="form.password"
          type="password"
          style="width: 100%"
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="平台" required>
        <el-select
          v-model="form.platform"
          style="width: 100%"
          placeholder="请选择平台"
          :disabled="!!form.id"
        >
          <el-option
            v-for="(item, index) in platformList"
            :label="item"
            :value="index"
            :key="index"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="公众号" required>
        <el-select
          v-model="form.accountChannelId"
          filterable
          placeholder="请选择公众号"
          style="width: 100%"
        >
          <el-option
            v-for="item in channelList"
            :key="item.id"
            :label="item.channelName"
            :value="item.id"
          >
            <span style="float: left">{{ item.channelName }}</span>
            <span style="margin-left: 15px; font-size: 13px"
              >{{ item.platformStr }} - {{ item.uname }}</span
            >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </div>
</template>

<script>
import { editAccountLogin, addAccountLogin } from "@/api/account";
import { platformList } from "@/assets/js/options";
import { mapGetters } from "vuex";

export default {
  name: "edit",
  props: {
    show: Boolean,
    account: Object,
  },
  data() {
    return {
      form: {},
      loading: false,
      platformList,
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          if (this.account) {
            this.form = {
              userName: this.account.userName,
              platform: this.account.platform,
              id: this.account.id,
              accountChannelId: this.account.accountChannelId,
            };
          } else {
            this.form = {};
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    channelList() {
      const { officialList } = this;
      const { platform } = this.form;
      return typeof platform === "number"
        ? officialList.filter((item) => item.platform === platform)
        : officialList;
    },
    ...mapGetters(["officialList"]),
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleSubmit() {
      const { userName, platform, accountChannelId, password, id } = this.form;
      if (!userName) {
        this.$message.warning("请输入用户名");
        return false;
      }
      if (!id && !password) {
        this.$message.warning("请输入密码");
        return false;
      }
      if (typeof platform !== "number") {
        this.$message.warning("请选择平台");
        return false;
      }
      if (!accountChannelId) {
        this.$message.warning("请选择公众号");
        return false;
      }
      if (id) {
        editAccountLogin(this.form.id, {
          password: this.form.password,
          accountChannelId: this.form.accountChannelId,
        }).then(() => {
          this.$message.success("保存成功");
          this.handleClose();
          this.$emit("refresh");
        });
      } else {
        addAccountLogin({
          userName: userName.trim(),
          platform,
          accountChannelId,
          password: password.trim(),
        }).then(() => {
          this.$message.success("保存成功");
          this.handleClose();
          this.$emit("refresh");
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.account-login-edit {
  width: 450px;
  box-sizing: border-box;
  padding: 20px;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
</style>
