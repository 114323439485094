<template>
  <div>
    <div class="part border-radius-6  box-shadow-light-grey padding-20 height-calc-type1">
      <div
        class="flex-between operation padding-bottom-20"
      >
        <div>
          <el-button @click="getList">刷新数据</el-button>
          <el-select
            class="margin-right-twentyFour margin-left-twentyFour"
            clearable
            placeholder="平台"
            v-model="platform"
            style="width: 120px"
            @change="handlePageChange(1)"
          >
            <el-option
              v-for="(item, index) in platformList"
              :label="item"
              :value="index"
              :key="index"
            >
            </el-option>

          </el-select>
          <el-input
            placeholder="用户姓名"
            v-model="userName"
            class="input-with-select margin-right-twentyFour"
            @keydown.native.enter="handlePageChange(1)"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handlePageChange(1)"
            ></el-button>
          </el-input>
          <el-input
            placeholder="公众号名称"
            v-model="channelName"
            class="input-with-select"
            @keydown.native.enter="handlePageChange(1)"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handlePageChange(1)"
            ></el-button>
          </el-input>
        </div>
        <el-button type="primary" @click="toEdit(null)">新增账号</el-button>
      </div>
      <el-table
        v-loading="loading"
        :data="accountList"

        height="calc(100vh - 300px)"
        style="width: 100%;"
      >
        <el-table-column prop="id" label="ID" width="100"> </el-table-column>
        <el-table-column prop="userName" min-width="120" label="用户名">
        </el-table-column>
        <el-table-column prop="channelName" min-width="120" label="公众号">
        </el-table-column>
        <el-table-column min-width="120" prop="platform" label="平台">
          <template slot-scope="scope">
            <span>{{ platformList[scope.row.platform] }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="120" prop="createdTime" label="创建时间">
        </el-table-column>
        <el-table-column label="操作" width="330px">
          <template slot-scope="scope">
            <el-button

              class="novel-login button-small"
              :datatype="scope.row.platform"
              :dataId="scope.row.id"
              >进入系统</el-button
            >
            <el-button
              class="button-small"
              type="primary"
              @click="toEdit(scope.row)"
              >修改</el-button
            >
            <el-button class='button-small' type='warning' @click='clearCache(scope.row)'>
              清除登录
            </el-button>
            <el-button
              class="button-small"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top:10px;text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-drawer :visible.sync="isEdit" direction="rtl" size="450px" title="修改">
      <edit
        :show="isEdit"
        @close="isEdit = false"
        :account="current"
        @refresh="getList"
      />
    </el-drawer>
  </div>
</template>

<script>
import Edit from './edit'
import { getLoginAccount, deleteAccountLogin,clearAccountCache } from '@/api/account'
import { platformList } from '@/assets/js/options'

export default {
  name: 'AccountLogin',
  data () {
    return {
      page: 1,
      accountList: [],
      loading: false,
      total: 0,
      platform: null,
      userName: null,
      channelName: null,
      current: null,
      isEdit: false,
      platformList
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    handleDelete (item) {
      this.$confirm('确定删除该书城账号?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteAccountLogin(item.id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {})
    },
    toEdit (item) {
      this.current = item
      this.isEdit = true
    },
    handlePageChange (page) {
      this.page = page
      this.getList()
    },
    getList () {
      this.loading = true
      getLoginAccount({
        page: this.page,
        pageSize: 15,
        platform: this.platform,
        userName: this.userName,
        channelName: this.channelName
      })
        .then(res => {
          this.accountList = res.list
          this.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    clearCache(row){
      let {id} = row;
      this.$confirm('确定清除缓存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        clearAccountCache(id).then(()=>{
          this.$message({
            showClose:true,
            message:'清除成功!',
            type:'success'
          })
        })
      })
    }
    
  },
  components: {
    Edit
  }
}
</script>

<style lang="scss" scoped>
.input-with-select {
  width: 200px;
}
.part {
  background: #fff;
  // padding: 0 15px;
}
.operation {
  background: #fff;
  // padding: 0 15px;
}
/deep/ .el-drawer {
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
</style>
